<template>
  <div class="agency-editor">
    <nav class="d-flex align-items-center justify-content-between">
      <div>
        <b-btn @click="$router.back()" variant="primary" class="back-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" stroke-width="2px" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
          </svg>
        </b-btn>
      </div>
      <div >
        <router-link to="/">

          <div class="img-box">
            <img src="@/assets/newentry/AISocialAgents-Dark.svg" alt="">
          </div>
        </router-link>
      </div>
      <div>
        &nbsp;
      </div>
    </nav>
    <iframe class="w-100 h-100" :src="editorUrl" />
  </div>
</template>

<script>
export default {
  name: "AgencyEditor",
  data: () => ({
    subdomain: 10,
  }),
  computed: {
    editorUrl() {
      return `${process.env.VUE_APP_EDITOR_URL}?site=${this.$route.query.id}-sa`
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
.agency-editor {
  position: fixed;
  inset: 0;
  display: grid;
  grid-template-rows: auto 1fr;

  nav {
    background: $primary;

    .back-btn {
      padding: 0px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1/1;
      border-radius: 0%;
    }

    .img-box {
      height: 23px;
      width: 120px;
      padding: 0 5px;
      background: white;
      border-radius: 5px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background: white;
      }
    }
  }

  iframe {
    border: none;
  }
}
</style>

</style>
